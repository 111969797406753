<template>
    <v-app id="inspire">
        <app-loader></app-loader>
        <app-bar-front-page></app-bar-front-page>
        <v-main>
            <v-container fluid fill-height>
                <v-layout align-center justify-center>
                <v-flex xs12 sm12 md8 lg4 v-show="showForm">
                    <v-card class="elevation-5 pa-3">
                    <!--<v-toolbar class="elevation-1" dark color="primary">
                        <v-toolbar-title>{{ title }}</v-toolbar-title>
                    </v-toolbar>-->
                    <v-card-text>
                        <div class="layout column align-center">
                        <img :src="getLogo" alt="Dovolilnice | Občina Bled - Ponastavitev gesla" height="auto">
                        <br><br>
                        <div>
                            <p class="headline font-weight-medium text-center">Dovolilnice | Občina Bled</p>
                        </div>
                        <div>
                            <p class="title font-weight-medium text-center">Ponastavitev gesla</p>
                        </div>
                        <v-layout align-center justify-center="">
                            <v-flex xs12 sm12 md10 lg10>
                                <div class="">
                                    <p style="text-align:center;" class="font-weight-medium">Za ponastavitev gesla vnesite svoj elektronski naslov in željeno novo geslo.</p>
                                </div>
                            </v-flex>
                        </v-layout>
                        </div> 
                        <br><br>
                        <v-form ref="passwordResetForm" v-model="valid" lazy-validation>
                        <v-text-field
                            append-icon="mdi-email"
                            name="login" label="E-mail" 
                            type="email" 
                            :rules="emailRules" 
                            v-model="username"
                            :error="error"
                            :error-messages="errors['message']"
                            @keyup.enter="passwordReset"
                            color="tertiary"
                            >
                        </v-text-field>
                        <v-text-field id="password" append-icon="mdi-lock" name="password" label="Geslo" type="password" :rules="passwordRules" v-model="password" @keyup.enter="passwordRest" color="tertiary"></v-text-field>
                        <v-text-field id="passwordConfirm" append-icon="mdi-lock" name="passwordConfirm" label="Ponovite novo geslo" type="password" :rules="passwordRules" v-model="passwordConfirm" @keyup.enter="passwordReset" color="tertiary"></v-text-field>
                        </v-form>
                    </v-card-text>
                    <v-card-actions v-if="!$vuetify.breakpoint.xsOnly">
                        <v-spacer></v-spacer>
                        <v-btn class="white--text" dark color="secondary" @click.native="redirectToLogin">Prijava</v-btn>
                        <v-btn class="white--text" dark color="secondary" @click.native='passwordReset' :loading="loading">Ponastavi geslo</v-btn>
                    </v-card-actions>
                    <v-card-text v-else>
                        <v-btn block outlined style="margin-bottom:10px;" class="white--text" dark color="secondary" @click.native="redirectToLogin">Prijava</v-btn>
                        <v-btn block outlined style="margin-bottom:10px;" class="white--text" dark color="secondary" @click.native='passwordReset' :loading="loading">Ponastavi geslo</v-btn>
                    </v-card-text>
                    </v-card>
                </v-flex>
                </v-layout>
            </v-container>


            <v-snackbar
                v-model="$store.getters.snackbar.display"
                :bottom="$store.getters.snackbar.y === 'bottom'"
                :left="$store.getters.snackbar.x === 'left'"
                :multi-line="$store.getters.snackbar.mode === 'multi-line'"
                :right="$store.getters.snackbar.x === 'right'"
                :timeout="$store.getters.snackbar.timeout"
                :top="$store.getters.snackbar.y === 'top'"
                :vertical="$store.getters.snackbar.mode === 'vertical'"
                >
                {{ $store.getters.snackbar.text }}
                <v-btn
                    color="white"
                    text
                    @click="snackbar"
                >
                    Zapri
                    </v-btn>
            </v-snackbar>
            <network-status></network-status>
        </v-main>
    </v-app>  
</template>

<script>
import router from '@/router'
import AppBarFrontPage from '@/components/layout/AppBarFrontPage'
import logo from '@/assets/grb_bled_135_135.png'
const NetworkStatus = () =>import('@/components/NetworkStatus')

export default {
    components: {
        AppBarFrontPage,
        NetworkStatus
    },
    data: () => ({
        title: 'Dovolilnice | Občina Bled - Ponastavitev gesla',
        valid: true,
        username: '',
        password: '',
        passwordConfirm: '',

        emailRules: [
            (v) => !!v || 'Elektronski naslov je obvezen.',
            (v) => /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'Vneseni elektronski naslov ni veljaven.'
        ],
        passwordRules: [
            (v) => !!v || 'Geslo je obvezno.',
            (v) => v.length >= 8 || 'Geslo mora vsebovati vsaj 8 mestni zapis.'
        ],

        loading: false,
        error: false,
        errors: {},
        showForm: false
    }),

    watch: {
        username(value) {
            if(this.username == null) {
                this.username = ''
                this.$refs.resetForm.resetValidation()
            }
        },

        password(value) {
            if(this.password == null) {
                this.password = null
                this.$refs.resetForm.resetValidation()
            }
        },

        passwordConfirm(value) {
            if(this.passwordConfirm == null) {
                this.passwordConfirm = null
                this.$refs.resetForm.resetValidation()
            }
        }
    },

    computed: {
        getLogo() {
            //return 'https://cloud.conforma.si/public/img/obcina-bled/grb_bled_135_135.png'
            return logo;
        }
    },

    methods: {
        passwordReset() {
            if(this.password != this.passwordConfirm) {
                this.errors = { message: 'Novo geslo in ponovitev novega gesla se ne ujemata' }
            } else {
                this.errors = {}
                this.$refs.passwordResetForm.validate();
                if(this.valid) {
                    this.$recaptcha("login").then(gtoken => {
                        let captchaRecaptchaResponse = gtoken;
                        this.loading = true
                        this.$store.dispatch('PASSWORD_RESET', {
                            email: this.username,
                            password: this.password,
                            password_confirmation: this.passwordConfirm,
                            token: this.$route.params.token,
                            'g-recaptcha-response': captchaRecaptchaResponse
                        })
                        .then(response => {
                            //console.log(response)
                            this.loading = false
                            this.$store.commit('SET_SNACKBAR_TEXT', 'Uspešno ste ponastavili svoje geslo. Za prijavo v sistem kliknite gumb Prijava.')
                            this.$store.commit('SET_SNACKBAR_DISPLAY', true) 

                            this.username = ''
                            this.password = ''
                            this.passwordConfirm = ''

                            this.$refs.passwordResetForm.reset();
                            this.$refs.passwordResetForm.resetValidation();
                        })
                        .catch(error => {
                            window.console.log(error)
                            if(error.response.status == 403) {
                                this.$store.commit('SET_SNACKBAR_TEXT', 'Ponastavitveni žeton je neveljaven ali pa uporabnik s tem elektronskim naslovom ne obstaja')
                                this.$store.commit('SET_SNACKBAR_DISPLAY', true) 
                            } else {
                                this.$store.commit('SET_SNACKBAR_TEXT', 'Pri ponastavljanju uporabniškega gesla je prišlo do napake')
                                this.$store.commit('SET_SNACKBAR_DISPLAY', true) 
                            }

                            this.loading = false
                        })
                    });
                }
            }
        },

        findToken(token) {
            this.$store.commit('SET_LOADER_TEXT', '')
            this.$store.commit('SET_LOADER', true)
            var vm = this;
            //window.console.log(token.length)
            if(token == "" || token.length != 60) {
                setTimeout(function() { 
                        vm.$store.commit('SET_LOADER', false)
                        vm.$router.push({
                        name: 'login'
                    })
                }, 700)
            }

            this.$store.dispatch('PASSWORD_RESET_TOKEN_FIND', token)
            .then(response => {
                //window.console.log(response)
                //window.console.log(response.status)
                if(response.status === 200) {
                    setTimeout(function() {
                        vm.showForm = true
                    }, 800)
                }
            }) 
            .catch(error => {
                //window.console.log(error)
                this.$router.push({
                    name: 'login'
                })
            })
            .finally(() => {
                //window.console.log("PASSWORD_RESET_TOKEN_FIND done")
                setTimeout(function() {
                    
                vm.$store.commit('SET_LOADER', false)
                }, 700)
            })
        },

        redirectToLogin() {
            this.$router.push({
                name: 'login'
            })
        },

        snackbar() {
            this.$store.state.snackbar.display = false
        }
    },

    created() {
        //window.console.log(this.$route.params.token)
        this.findToken(this.$route.params.token)
    },

    destroyed() {
        this.$store.commit('SET_SNACKBAR_DISPLAY', false) 
    }
}
</script>

<style scoped>

    #inspire{
        background-image: url("../../assets/bckpattern.png");
        background-repeat: repeat;
    }
</style>


